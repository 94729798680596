const GUIDEBANNER = [{ space_src: "baidujs", space_type: "native", space_id: "", space_js: "" }];
const INTER = [{ space_src: "baidujs", space_type: "inters", space_id: "u6922733", space_js: "//gflpjby.gnpad.cn/source/kf/en_uu/gxx.js" }];
const HOMEDIALOG = [
  { space_src: "baidujs", space_type: "native", space_id: "u6922734", space_js: "//gflpjby.gnpad.cn/site/l/static/g/source/fo_vvh/openjs/yc.js" }
];
const HOMENATIVEINTER = [
  { space_src: "baidujs", space_type: "inters", space_id: "u6924030", space_js: "//gflpjby.gnpad.cn/production/cx/wfm/source/t/dp/openjs/d.js" }
];
const CENTER = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6922735",
    space_js: "//gflpjby.gnpad.cn/common/mhgpw/w/openjs/i_z_e.js"
  }
];
const HOMECENTER = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6925223",
    space_js: "//gflpjby.gnpad.cn/site/hc/b/source/krz/rr/u.js"
  }
];
const BANNER = [
  { space_src: "baidujs", space_type: "native", space_id: "u6922736", space_js: "//gflpjby.gnpad.cn/production/n/ihq-x/resource/xja/production/h.js" }
];
const POETTOP = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6922735",
    space_js: "//gflpjby.gnpad.cn/common/mhgpw/w/openjs/i_z_e.js"
  }
];
const POETCENTER = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6922735",
    space_js: "//gflpjby.gnpad.cn/common/mhgpw/w/openjs/i_z_e.js"
  }
];
const POETBANNER = [
  { space_src: "baidujs", space_type: "native", space_id: "u6922736", space_js: "//gflpjby.gnpad.cn/production/n/ihq-x/resource/xja/production/h.js" }
];
const POEMTOP = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6922735",
    space_js: "//gflpjby.gnpad.cn/common/mhgpw/w/openjs/i_z_e.js"
  }
];
const POEMCENTER = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6922735",
    space_js: "//gflpjby.gnpad.cn/common/mhgpw/w/openjs/i_z_e.js"
  }
];
const POEMBANNER = [
  { space_src: "baidujs", space_type: "native", space_id: "u6922736", space_js: "//gflpjby.gnpad.cn/production/n/ihq-x/resource/xja/production/h.js" }
];
const POEMINTER = [{ space_src: "baidujs", space_type: "inters", space_id: "u6922733", space_js: "//gflpjby.gnpad.cn/source/kf/en_uu/gxx.js" }];
const POETLISTCENTER = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6922735",
    space_js: "//gflpjby.gnpad.cn/common/mhgpw/w/openjs/i_z_e.js"
  }
];
const POETLISTBANNER = [
  { space_src: "baidujs", space_type: "native", space_id: "u6922736", space_js: "//gflpjby.gnpad.cn/production/n/ihq-x/resource/xja/production/h.js" }
];

/** 广告位配置 */
export const SPACE_MAP = {
  Guide_banner: GUIDEBANNER, // 引导页
  Home_inter: INTER, // 插屏
  Home_dialog: HOMEDIALOG, // 自定义弹窗
  Home_native_inter: HOMENATIVEINTER, //首页-自定义插屏
  Home_top: CENTER, // 首页信息流top
  Home_center: HOMECENTER, // 首页信息流
  Home_banner: BANNER, // 首页Banner
  Poet_top: POETTOP, // 诗人详情信息流top
  Poet_center: POETCENTER, // 诗人详情信息流center
  Poet_banner: POETBANNER, // 诗人详情banner
  Poem_top: POEMTOP, // 诗词详情信息流top
  Poem_center: POEMCENTER, // 诗词详情信息流center
  Poem_banner: POEMBANNER, // 诗词详情banner
  Poem_inter: POEMINTER, // 诗词详情官方插屏
  PoetList_center: POETLISTCENTER, // 诗人列表信息流
  PoetList_banner: POETLISTBANNER // 诗人列表banner
};
